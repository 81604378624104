import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #191919;
  position: relative;
  z-index: 500;
  max-width: 1800px;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  padding: 15px;
  max-width: 1400px;
  margin: 0 auto;
`;

export const DropdownWrapper = styled.div`
  text-align: center;
  .dropdown {
  }
  .dropdown-toggle {
    min-width: 250px;
    font-family: Montserrat, "sans-serif";
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
    height: 40px;
    background-color: #120f0f !important;
    border: none !important;
    outline: none !important;
    .bold {
      font-weight: 500;
    }
  }
  .dropdown-menu {
    min-width: 250px;
    font-family: Montserrat, "sans-serif";
    background-color: #120f0f !important;
    a {
      font-weight: 400;
      color: #fff;
      font-size: 14px;
      transition: 0.5s;
      text-transform: uppercase;
      &:hover {
        background: none !important;
        padding-left: 20px;
      }
    }
  }
`;

export const SelectionButton = styled.button`
  color: grey;
  border: none;
  background-color: transparent;
  font-family: Montserrat, "sans-serif";
  &.selected {
    color: #fff;
    text-decoration: underline;
  }
`;
