import {
  ContentWrapper,
  Section,
  List,
  ListItem,
  Title,
  Bullet,
  ListWrapper,
  BackgroundImage,
} from "./styled";
import bullet from "../../assets/img/bullet.svg";
import backgroundImage from "../../assets/img/introduction-thumbnail.png";

const LIST_ITEMS_NORWEGIAN = [
  {
    type: "regular",
    text: "Organisasjonspsykolog",
  },
  {
    type: "regular",
    text: "Klimapsykolog",
  },
  {
    type: "regular",
    text: "Førsteamanuensis Bærekraft Universitet i Oslo",
  },
  {
    type: "regular",
    text: "PhD økonomisk psykologi og atferdsøkonomi",
  },
  {
    type: "regular",
    text: "Fulbright gjesteforsker Harvard Business School",
  },
  {
    type: "regular",
    text: "Psykolog i Autentisk Ledelse",
  },
  {
    type: "regular",
    text: "Psykolog i PsykoPizza",
  },
  {
    type: "separator",
    text: "Tidligere",
  },
  {
    type: "regular",
    text: "Førsteamanuensis Handelshøyskolen BI",
  },
  {
    type: "regular",
    text: "Førsteamanuensis Universitetet i Oslo",
  },
];

const LIST_ITEMS_ENGLISH = [
  {
    type: "regular",
    text: "Organizational Psychologist",
  },
  {
    type: "regular",
    text: "Climate Psychologist",
  },
  {
    type: "regular",
    text: "Associate Professor Sustainability Lab University of Oslo",
  },
  {
    type: "regular",
    text: "PhD Behavioral Economics and Economic Psychology",
  },
  {
    type: "regular",
    text: "Fulbright Post Doc Scholar Harvard Business School",
  },
  {
    type: "regular",
    text: "Psychologist Authentic Leadership",
  },
  {
    type: "regular",
    text: "Psychologist PsykoPizza ",
  },
  {
    type: "separator",
    text: "Previously",
  },
  {
    type: "regular",
    text: "Associate Professor BI Norwegian Business School",
  },
  {
    type: "regular",
    text: "Associate Professor University of Oslo",
  },
];

const Introduction = () => {
  const language = localStorage.getItem("karevold-lang");

  return (
    <Section>
      <BackgroundImage src={backgroundImage} />
      <ContentWrapper>
        <Title>Dr. Knut Ivar Karevold (PHD)</Title>
        <ListWrapper>
          <List>
            {language === "en"
              ? LIST_ITEMS_ENGLISH.map((item) => (
                  <ListItem className={item.type}>
                    <Bullet src={bullet} />
                    {item.text}
                  </ListItem>
                ))
              : LIST_ITEMS_NORWEGIAN.map((item) => (
                  <ListItem className={item.type}>
                    <Bullet src={bullet} />
                    {item.text}
                  </ListItem>
                ))}
          </List>
        </ListWrapper>
      </ContentWrapper>
    </Section>
  );
};

export default Introduction;
