import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #191919;
  max-width: 1800px;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  padding: 15px;
  max-width: 1400px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-family: Montserrat, "sans-serif";
  font-weight: 700;
  color: white;
  text-align: center;
  text-transform: uppercase;
  color: #ebb954;
  font-size: 45px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const ContactWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: block;
  text-align: center;
  margin-top: 40px;
`;

export const Button = styled.button`
  border: none;
  margin-left: 10px;
  margin-right: 10px;
  padding: 12px 35px;
  border-radius: 8px;
  min-width: 150px;
  background-color: rgb(243, 243, 243);
  font-family: Montserrat, "sans-serif";
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #232323;
`;

export const ContactLink = styled.a`
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px dashed #232323;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: #ebb954;
  }
  @media (max-width: 900px) {
    display: block;
    margin-bottom: 15px !important;
  }
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 170px;
  margin-top: 40px;
`;

export const ReadMoreTitle = styled.h5`
  color: #fff;
  text-align: center;
  font-family: Montserrat, "sans-serif";
  font-weight: 500;
  font-size: 16px;
  margin-top: 25px;
`;

export const LinkWrapper = styled.div`
  text-align: center;
  margin-top: 25px;
  @media (max-width: 900px) {
    a {
      display: block;
    }
  }
`;

export const LinkSeparator = styled.span``;

export const SingleLink = styled.a`
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
  font-family: Montserrat, "sans-serif";
  font-size: 14px;
  &:hover {
    cursor: pointer;
    color: #ebb954;
  }
`;

export const Credits = styled.span`
  color: #fff;
  margin: 0 auto;
  display: block;
  text-align: center;
  font-size: 14px;
  font-family: Montserrat, "sans-serif";
  margin-top: 25px;
`;

export const CreditsLink = styled.a`
  color: #ebb954;
  text-decoration: none;
  font-size: 14px;
  &:hover {
    color: #ebb954;
  }
`;
