import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section`
  background-color: #191919;
  max-width: 1800px;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  padding: 100px 15px;
  max-width: 1400px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-family: Montserrat, "sans-serif";
  font-weight: 700;
  color: white;
  text-align: center;
  text-transform: uppercase;
  color: #ebb954;
  font-size: 45px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const Description = styled.p`
  color: #fff;
  max-width: 810px;
  margin: 0 auto;
  margin-top: 15px;
  line-height: 1.8rem;
  text-align: center;
  font-family: Montserrat, "sans-serif";
  font-weight: 300;
`;

export const GridWrapper = styled.div`
  margin-top: 50px;
`;

export const SingleLink = styled.a`
  text-decoration: none;
`;

export const ClientsRow = styled(Row)``;

export const ClientCol = styled(Col)``;

export const Item = styled.div`
  background-color: #1c1c1c;
  border-radius: 5px;
  border: 1px solid;
  border-color: #232323;
  margin-bottom: 15px;
  padding: 20px;
  display: flex;
  display: flex;
  align-items: center;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    border-color: #ebb954;
    h5 {
      color: #ebb954;
    }
  }
`;

export const ItemTitle = styled.h5`
  color: #fff;
  font-family: Montserrat, "sans-serif";
  font-size: 18px;
  display: block;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
`;
