import styled from "@emotion/styled";

export const Section = styled.section`
  height: 100vh;
  background-color: #191919;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
  @media (max-width: 767px) and (max-height: 2532px) {
    min-height: 900px;
  }
`;

export const BackgroundImage = styled.img`
  position: absolute;
  height: 90vh;
  right: 0px;
  bottom: 0px;
  mix-blend-mode: lighten;
  z-index: 1;
  @media (max-width: 1100px) {
    opacity: 0.4;
    min-height: 100%;
    height: 100%;
    bottom: -0px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 15px;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  padding-top: 200px;
  @media (max-width: 900px) {
    padding-top: 100px;
  }
`;

export const Title = styled.h2`
  font-family: Montserrat, "sans-serif";
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  color: #ebb954;
  font-size: 45px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const ListWrapper = styled.div`
  margin-top: 40px;
`;

export const List = styled.ul`
  padding-left: 0px;
  list-style-type: none;
`;

export const Bullet = styled.img`
  width: 28px;
  margin-right: 15px;
`;

export const ListItem = styled.li`
  color: #fff;
  font-family: Montserrat, "sans-serif";
  margin-bottom: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  &.separator {
    color: grey;
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
    img {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    &.separator {
      color: #fff;
    }
  }
`;
