// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
body, html {
  background-color: #191919;
}

.full-page-content{
  overflow-x: hidden;
  background-color: #191919;
}`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;;EAEE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":["body,\nbody, html {\n  background-color: #191919;\n}\n\n.full-page-content{\n  overflow-x: hidden;\n  background-color: #191919;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
