import {
  ContentWrapper,
  Description,
  GridWrapper,
  Section,
  ServiceCol,
  ServiceIcon,
  ServicesRow,
  ServiceTitle,
  ServiceWrapper,
  Title,
} from "./styled";
import Ribbon from "../../assets/img/ribbon.svg";

const ALL_SERVICES_NORWEGIAN = [
  "Lukke de strategiske gapene",
  "Skape en kraftfull organisasjon",
  `Autentisk ledelse`,
  "Relasjoner som betyr noe",
  "De viktige samtalene",
  "De vanskelige spørsmålene",
  "De riktige beslutingene",
  "Fra innovative ideer til hadling",
];

const ALL_SERVICES_ENGLISH = [
  "Close the strategic gaps",
  "Design a powerful organization",
  `Authentic leadership`,
  "Relationships that matter",
  "The important conversations",
  "Asking the difficult questions",
  "Making the right decisions",
  "From innovative ideas to action",
];

const Services = () => {
  const language = localStorage.getItem("karevold-lang");

  return (
    <Section>
      <ContentWrapper>
        <Title>{language === "en" ? "My services" : "Mine tjenester"}</Title>
        <Description>
          {language === "en"
            ? "I deliver customized and innovative solutions based on the latest behavioral science research. The goal is to develop people, relationships, communication, decision-making, teamwork and organizational designs. My services include leadership development, team development, organizational development, change, strategy, nudging for sustainable and healthy choices, climate communication, risk decision making, personal growth, and effective project execution."
            : "Jeg leverer innovative løsninger basert på den nyeste og mest aktuelle psykologiske kunnskapen. Målet er å utvikle folk, relasjoner, kommunikasjon, beslutninger, teamarbeid og organisatorisk design. Tjenestene mine omfatte lederutvikling, teamutvikling, organisasjonsutvikling, endring, strategi, nudging for bærekraft og helse, klimakommunikasjon, personlig utvikling, og effektiv prosjektgjennomføring."}
        </Description>
        <GridWrapper>
          <ServicesRow>
            {language === "no"
              ? ALL_SERVICES_NORWEGIAN.map((service) => (
                  <ServiceCol md={3}>
                    <ServiceWrapper>
                      <ServiceIcon src={Ribbon} />
                      <ServiceTitle>{service}</ServiceTitle>
                    </ServiceWrapper>
                  </ServiceCol>
                ))
              : ALL_SERVICES_ENGLISH.map((service) => (
                  <ServiceCol md={3}>
                    <ServiceWrapper>
                      <ServiceIcon src={Ribbon} />
                      <ServiceTitle>{service}</ServiceTitle>
                    </ServiceWrapper>
                  </ServiceCol>
                ))}
          </ServicesRow>
        </GridWrapper>
      </ContentWrapper>
    </Section>
  );
};

export default Services;
