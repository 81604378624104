import {
  ContentWrapper,
  Description,
  Item,
  ItemTitle,
  GridWrapper,
  Section,
  Title,
  ClientsRow,
  ClientCol,
  SingleLink,
} from "./styled";

const CLIENTS_AND_PROJECTS = [
  "4Service",
  "Ahus",
  "Bergen Kommune",
  "Blått Kompetansesenter Sjømat Frøya",
  "Bondelaget",
  "Europaparlamentet",
  "Forsvaret",
  "Høyskolen Innlandet INN",
  "Kongsberggruppen",
  "Miljødirektoratet",
  "Norges Bank Oljefondet NBIM",
  "Noria",
  "Orkla Foods",
  "Rogaland Fylkeskommune",
  "Statens Vegvesen",
  "Sykehuset i Vestfold",
  "Tromsø Kommune",
  "Viken Fylkeskommune",
  "Voss Herad",
  "4Service",
  "AFF",
  "Aftenposten",
  "Aker BP",
  "Aker Energy",
  "Aker Solutions",
  "Alfred Berg",
  "Amgen",
  "Asko",
  "AstraZeneca",
  "Bama",
  "BI Handelshøyskolen",
  "Decisions",
  "Det Kongelige Hoff",
  "DinePenger",
  "Duke Corporate Education",
  "EGGS Design",
  "Embriq",
  "Equinor",
  "Fazer Food Services",
  "Folkehelseinstituttet",
  "Haavind Advokatfirma",
  "Helse Sør-Øst",
  "HR Norge",
  "Junglemap",
  "Kiwi",
  "Kvaerner",
  "Legeforeningen",
  "Leith Society",
  "MSD",
  "NAV",
  "Nordea",
  "Nordic Choice Hotels",
  "NorgesGruppen",
  "Norsar",
  "Norsk Psykologforening",
  "OsloMet",
  "Oticon",
  "PGS",
  "PhotoCure",
  "Rema 1000",
  "Simonsen Advokatfirma",
  "Sjømatrådet",
  "Skatteetaten",
  "Skedsmo Kommune",
  "Sykehusapotekene",
  "Telenor",
  "ThyssenKrupp",
  "Tine",
  "TV2",
  "Uniconsult",
  "Universitetet i Oslo",
];

const ClientsProjects = () => {
  const language = localStorage.getItem("karevold-lang");

  return (
    <Section>
      <ContentWrapper>
        <Title>
          {language === "en" ? "Clients & projects" : "Kunder & prosjekter"}
        </Title>
        <Description>
          {language === "en"
            ? "I have experience with a range of different sectors and industries: Energy, Food, Professional Services and Consulting, Health, Technology, IT, Pharmaceuticals, Financial Services, Logistics, Construction, Media, and the Public Sector. I work for a few clients at a time, and develop deep relationships with strong commitments to the work we do together."
            : "Jeg har erfaring fra mange ulike bransjer og sektorer: Energi, Mat, Rådgiving- og konsulentvirksomhet, Helse, Teknologi, IT, Farmasøytisk industri, Logistikk, Bygg- og anlegg, Finans, Media, og Offentlig sektor. Jeg jobber for noen få oppdragsgivere om gangen, og utvikler dype relasjoner med sterk forpliktelse til arbeidet vi gjør sammen."}
        </Description>
        <GridWrapper>
          <ClientsRow>
            {CLIENTS_AND_PROJECTS.map((item) => (
              <ClientCol md={4}>
                <Item>
                  <ItemTitle>{item}</ItemTitle>
                </Item>
              </ClientCol>
            ))}
          </ClientsRow>
        </GridWrapper>
      </ContentWrapper>
    </Section>
  );
};

export default ClientsProjects;
