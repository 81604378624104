import styled from "@emotion/styled";
import { Row } from "react-bootstrap";

export const Section = styled.section`
  background-color: #191919;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  padding: 100px 15px;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 2;
  position: relative;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  height: 100%;
  right: 0px;
  mix-blend-mode: lighten;
  z-index: 1;
  @media (max-width: 1100px) {
    opacity: 0.4;
  }
`;

export const Title = styled.h2`
  font-family: Montserrat, "sans-serif";
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  color: #ebb954;
  font-size: 45px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const Description = styled.p`
  color: #fff;
  max-width: 800px;
  margin-top: 15px;
  line-height: 1.8rem;
  font-family: Montserrat, "sans-serif";
  font-weight: 300;
`;

export const ListWrapper = styled.div`
  margin-top: 50px;
`;

export const SingleItem = styled.div`
  max-width: 530px;
  margin-bottom: 25px;
`;

export const ItemTitle = styled.h4`
  color: #ebb954;
  font-family: Montserrat, "sans-serif";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
`;

export const ItemDescription = styled.p`
  font-family: Montserrat, "sans-serif";
  color: #fff;
  margin-top: 5px;
`;
