import styled from "@emotion/styled";
import { Col, Row } from "react-bootstrap";

export const Section = styled.section`
  background-color: #1c1c1c;
  max-width: 1800px;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  padding: 100px 15px;
  max-width: 1400px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-family: Montserrat, "sans-serif";
  font-weight: 700;
  color: white;
  text-align: center;
  text-transform: uppercase;
  color: #ebb954;
  font-size: 45px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const Description = styled.p`
  color: #fff;
  max-width: 810px;
  margin: 0 auto;
  margin-top: 15px;
  line-height: 1.8rem;
  text-align: center;
  font-family: Montserrat, "sans-serif";
  font-weight: 300;
`;

export const GridWrapper = styled.div`
  margin-top: 50px;
`;

export const ServicesRow = styled(Row)``;

export const ServiceCol = styled(Col)`
  margin-bottom: 25px;
`;

export const ServiceWrapper = styled.div`
  background-color: #191919;
  border-radius: 7px;
  border-width: 1px;
  border-style: solid;
  border-color: #232323;
  padding: 20px;
`;

export const ServiceIcon = styled.img`
  display: block;
  margin: 0 auto;
  height: 60px;
`;

export const ServiceTitle = styled.h4`
  color: #fff;
  text-align: center;
  font-family: Montserrat, "sans-serif";
  font-weight: 400;
  font-size: 18px;
  margin-top: 15px;
`;
