import {
  ContentWrapper,
  BackgroundImage,
  Section,
  Title,
  ListWrapper,
  Subtitle,
  List,
  ListItem,
  RightContent,
  InnerContent,
} from "./styled";
import backgroundImage from "../../assets/img/psychology-thumbnail.jpg";

const ROLES_NORWEGIAN = [
  "Partner og Direktør Institutt for Klimapsykologi – Klimapsykologene",
  "Førsteamanuensis Bærekraftlaboratoriet Universitet i Oslo",
  "Direktør i GreeNudge (2014-2019)",
];

const ROLES_ENGLISH = [
  "Partner and Director Institute for Climate Psychology",
  "Associate Professor Sustainability Lab University of Oslo",
  "Director GreeNudge (2014-2019)",
];

const PROJECTS_NORWEGIAN = [
  "Klimanudge: hvordan påvirke forbrukere til mer bærekraftige valg?",
  "Bærekraftsmåling: hvordan kan ledere og ledergrupper skåre virksomhetens bærekraft?",
  "Bærekraftig ledelse: hvordan utvikle fremtidens lederskap?",
  "Bærekraftig norsk mat: klima, bærekraft og helse i praksis",
  "Klimakommunikasjon: hvordan påvirke til klimasmarte valg?",
  "Bærekraftige nordiske matvalg: hvordan bruke nudge som klimastrategi?",
  "Bærekraftige helsevalg: hvordan påvirke til sunnere livsstil?",
];

const PROJECTS_ENGLISH = [
  "Climate Nudge: How to influence consumers towards more sustainable choices?",
  "How can leaders and management teams score business sustainability?",
  "Sustainable Leadership: How to develop leadership for the future?",
  "Sustainable Food: Sustainability, Climate Footprints & Food in practice",
  "Climate Communcation: How to Influence Climate Friendly Behaviors?",
  "Sustainable Transportation: How to Nudge Choices?",
  "Sustainable Food Choices: How to Nudge Healthier Food Choices?",
];

const PsychologySustainability = () => {
  const language = localStorage.getItem("karevold-lang");

  return (
    <Section>
      <BackgroundImage src={backgroundImage} />
      <ContentWrapper>
        <RightContent>
          <InnerContent>
            <Title>
              {language === "en"
                ? "Psychology & sustainability"
                : "Psykologi & bærekraft"}
            </Title>
            <ListWrapper>
              <Subtitle>{language === "en" ? "Roles" : "Roller"}</Subtitle>
              <List>
                {language === "en"
                  ? ROLES_ENGLISH.map((role) => <ListItem>{role}</ListItem>)
                  : ROLES_NORWEGIAN.map((role) => <ListItem>{role}</ListItem>)}
              </List>
            </ListWrapper>
            <ListWrapper>
              <Subtitle>
                {language === "en" ? "Projects" : "Prosjekter"}
              </Subtitle>
              <List>
                {language === "en"
                  ? PROJECTS_ENGLISH.map((project) => (
                      <ListItem>{project}</ListItem>
                    ))
                  : PROJECTS_NORWEGIAN.map((project) => (
                      <ListItem>{project}</ListItem>
                    ))}
              </List>
            </ListWrapper>
          </InnerContent>
        </RightContent>
      </ContentWrapper>
    </Section>
  );
};
export default PsychologySustainability;
