import { useEffect } from "react";
import Approach from "./components/approach";
import ClientsProjects from "./components/clients-projects";
import Contact from "./components/contact";
import Introduction from "./components/introduction";
import Navigation from "./components/navigation";
import PsychologySustainability from "./components/psychology-sustainability";
import Services from "./components/services";

const Index = () => {
  const language = localStorage.getItem("karevold-lang");
  useEffect(() => {
    if (!language) {
      localStorage.setItem("karevold-lang", "no");
    }
  }, [language]);

  return (
    <div className="full-page-content">
      <Navigation />
      <Introduction />
      <PsychologySustainability />
      <Approach />
      <Services />
      <ClientsProjects />
      <Contact />
    </div>
  );
};

export default Index;
