import styled from "@emotion/styled";

export const Section = styled.section`
  background-color: #191919;
  position: relative;
  max-width: 1800px;
  margin: 0 auto;
`;

export const BackgroundImage = styled.img`
  position: absolute;
  height: 100%;
  left: -100px;
  mix-blend-mode: lighten;
  z-index: 1;
  @media (max-width: 1100px) {
    opacity: 0.4;
  }
`;

export const ContentWrapper = styled.div`
  padding: 15px;
  max-width: 1400px;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  padding-top: 200px;
  padding-bottom: 150px;
`;

export const RightContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const InnerContent = styled.div`
  text-align: left;
`;

export const Title = styled.h2`
  font-family: Montserrat, "sans-serif";
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  color: #ebb954;
  font-size: 45px;
  @media (max-width: 900px) {
    font-size: 36px;
  }
`;

export const ListWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const Subtitle = styled.h4`
  color: #ebb954;
  font-family: Montserrat, "sans-serif";
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
`;

export const List = styled.ul`
  list-style-type: none;
  padding-left: 0px;
  margin: 10px 0px;
`;

export const ListItem = styled.li`
  font-family: Montserrat, "sans-serif";
  color: #fff;
  margin-bottom: 10px;
`;
