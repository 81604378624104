import {
  Button,
  ContactLink,
  ContactWrapper,
  ContentWrapper,
  Credits,
  CreditsLink,
  LinkWrapper,
  Logo,
  ReadMoreTitle,
  Section,
  SingleLink,
  Title,
} from "./styled";
import wwfLogo from "../../assets/img/wwf-logo.jpg";

const LINKS = [
  {
    display: "klimapsykologene.no",
    link: "https://www.klimapsykologene.no",
  },
  {
    display: "sv.uio.no",
    link: "https://www.sv.uio.no/forskning/satsinger/berekraftlaboratoriet/",
  },
  {
    display: "barekraft.org",
    link: "https://barekraft.org",
  },
  {
    display: "autentiskledelse.no",
    link: "https://autentiskledelse.no",
  },
  {
    display: "psykopizza.no",
    link: "https://psykopizza.no",
  },
];

const Contact = () => {
  const language = localStorage.getItem("karevold-lang");

  return (
    <Section>
      <ContentWrapper>
        <Title>{language === "en" ? "Contact me" : "Kontakt meg"}</Title>
        <ContactWrapper>
          <ContactLink href="mailto:knut.ivar@karevold.no">
            {language === "en" ? "Email" : "E-post"}: knut.ivar@karevold.no
          </ContactLink>
          <ContactLink href="tel:+4791707075">
            {language === "en" ? "Phone" : "Telefon"}: +47 917 07 075
          </ContactLink>
          <ContactLink href="https://www.linkedin.com/in/knut-ivar-karevold-49284a1">
            {language === "en"
              ? "Follow me on LinkedIn"
              : "Følg meg på LinkedIn"}
          </ContactLink>
        </ContactWrapper>
        <Logo src={wwfLogo} />
        <LinkWrapper>
          {LINKS.map((item) => (
            <SingleLink href={item.link}>{item.display}</SingleLink>
          ))}
        </LinkWrapper>
        <Credits>
          {language === "en"
            ? "Design and development by"
            : "Design og utvikling av"}{" "}
          <CreditsLink href="https://www.linkedin.com/in/andreasskaalerud/">
            Andreas Skaalerud
          </CreditsLink>
        </Credits>
      </ContentWrapper>
    </Section>
  );
};

export default Contact;
