import {
  BackgroundImage,
  ContentWrapper,
  Description,
  ItemDescription,
  ItemTitle,
  ListWrapper,
  Section,
  SingleItem,
  Title,
} from "./styled";
import backgroundImage from "../../assets/img/approach-thumbnail.jpg";

const LIST_ITEMS_NORWEGIAN = [
  {
    title: "Psykologisk anarkist",
    description: "Unike kombinasjoner av modeller og metoder",
  },
  {
    title: "Alternativt og innovativt perspektiv",
    description: "Nye tanker og løsninger",
  },
  {
    title: "De skjulte mønstrene og sammenhengene",
    description: "Få øye på koplingene.",
  },
  {
    title: "Fra ord til handling",
    description: "Enkle løsninger på komplekse problemer",
  },
  {
    title: "Optimisme, energi og pågangsmot",
    description: "Kraft og fart i gjennomføringen",
  },
  {
    title: "Praktisk akademiker",
    description:
      "Akademisk erfaring som førsteamanuensis ved Handelshøyskolen BI og Universitetet i Oslo",
  },
];

const LIST_ITEMS_ENGLISH = [
  {
    title: "Psychological anarchist",
    description: "Unique combinations of different models and tools.",
  },
  {
    title: "Alternative and innovative perspectives",
    description: "New ideas and solutions.",
  },
  {
    title: "The hidden patterns and links",
    description: "Connecting the dots.",
  },
  {
    title: "From knowledge to action",
    description: "Simple solutions for complex problems.",
  },
  {
    title: "Optimism, energy and drive",
    description: "Power and speed in execution.",
  },
  {
    title: "Practical academic",
    description:
      "Academic experience as Associate Professor at BI Norwegian Business School and the University of Oslo",
  },
];

const Approach = () => {
  const language = localStorage.getItem("karevold-lang");

  return (
    <Section>
      <BackgroundImage src={backgroundImage} />
      <ContentWrapper>
        <Title>{language === "en" ? "My approach" : "Min tilnærming"}</Title>
        <Description>
          {language === "en"
            ? `I am an innovative conceptual thinker who uses psychology to solve organizational problems. I help my clients find simple and actionable solutions to complex challenges. It is always useful to understand if something is an individual, group or organizational issue. It is always useful to frame problems in terms of structures and systems, culture and attitudes, leadership and decision making, or communication and cooperation. Everything is connected with everything else, but change always starts by doing one thing differently.`
            : "Jeg er en innovativ konseptuell tenker som anvender psykologi til å løse organisatoriske problemer. Jeg hjelper kundene mine til å finne enkle og handlingsorienterte løsninger på komplekse problemer. Det er alltid nyttig å forstå om en utfordring er knyttet til individer, grupper eller organisasjonen totalt sett. Det er alltid nyttig å ramme inn problemer i form av strukturer og systemer, kultur og holdninger, lederskap og beslutninger, eller kommunikasjon og samarbeid. Alt henger sammen med alt, men man må starte med å endre en helt konkret ting for å komme videre."}
        </Description>
        <ListWrapper>
          {language === "en"
            ? LIST_ITEMS_ENGLISH.map((item) => (
                <SingleItem>
                  <ItemTitle>{item.title}</ItemTitle>
                  <ItemDescription>{item.description}</ItemDescription>
                </SingleItem>
              ))
            : LIST_ITEMS_NORWEGIAN.map((item) => (
                <SingleItem>
                  <ItemTitle>{item.title}</ItemTitle>
                  <ItemDescription>{item.description}</ItemDescription>
                </SingleItem>
              ))}
        </ListWrapper>
      </ContentWrapper>
    </Section>
  );
};

export default Approach;
