import { ContentWrapper, DropdownWrapper, Section } from "./styled";
import Dropdown from "react-bootstrap/Dropdown";

const Navigation = () => {
  const language = localStorage.getItem("karevold-lang");

  const selectLanguage = (language) => {
    localStorage.setItem("karevold-lang", language);
    window.location.reload();
  };

  return (
    <Section>
      <ContentWrapper>
        <DropdownWrapper>
          <Dropdown className="dropdown">
            <Dropdown.Toggle className="dropdown-toggle" id="dropdown-basic">
              {language === "en" ? "Chosen language: " : "Valgt språk: "}
              <span className="bold">
                {language === "en" ? "English" : "Norsk"}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu">
              <Dropdown.Item onClick={() => selectLanguage("en")}>
                {language == "en" ? "English" : "Engelsk"}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => selectLanguage("no")}>
                {language === "en" ? "Norwegian" : "Norsk"}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </DropdownWrapper>
      </ContentWrapper>
    </Section>
  );
};

export default Navigation;
